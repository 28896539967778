var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"sb",attrs:{"width":(_vm.width + "px"),"right":"","shadow":"","no-close-on-esc":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-link',{staticClass:"mr-2 text-info",on:{"click":_vm.onCancel}},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'arrow-left']}}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(_vm.parentTitle),expression:"parentTitle"}]})],1),_vm._v("\n      - "+_vm._s(_vm.displayTitle)+"\n    ")]},proxy:true},{key:"footer",fn:function(){return [(!_vm.isMobile)?_c('div',{staticClass:"d-flex bg-light align-items-center px-3 py-2"},[_c('strong',{staticClass:"mr-auto"}),_vm._v(" "),_c('b-btn',{attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.onCancel}},[_vm._v("\n          "+_vm._s(_vm.$t('eDoc_form_a_close'))+"\n        ")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[(_vm.state)?_c('div',{staticClass:"bg-white px-1 px-lg-3"},[(_vm.userId)?_c('data-layout',{ref:"dt",staticClass:"flex-grow-1 text-nowrap",attrs:{"fields":_vm.fields,"filter-fields":_vm.filterFields,"filter":_vm.filter,"order":_vm.order,"repo":"customers.talks","parent-id":_vm.userId,"height":_vm.height,"limit":50,"card-collapse":"","set-item-variant":function (item) { return item.deleted_at !== null ? 'table-danger' : ''; }},on:{"on-filter-submit":_vm.onFilterSubmit,"on-sort":_vm.onSort},scopedSlots:_vm._u([{key:"card(header)",fn:function(ref){
var item = ref.data.item;
return [(item)?_c('div',{staticClass:"text-wrap"},[(item.user_a)?_c('user-profile-link',{attrs:{"user":item.user_a,"name-variant":(item.status_a === 'blocked') ? 'danger' : undefined}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-muted small"},[_vm._v("\n              ("+_vm._s(item.status_a)+")\n            ")]),_vm._v(" "),_c('font-awesome-icon',{attrs:{"icon":"arrow-right","size":"sm"}}),_vm._v(" "),(item.user_b)?_c('user-profile-link',{attrs:{"user":item.user_b,"name-variant":(item.status_b === 'blocked') ? 'danger' : undefined}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-muted small"},[_vm._v("\n              ("+_vm._s(item.status_b)+")\n            ")])],1):_vm._e(),_vm._v(" "),(item)?_c('div',{staticClass:"small"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$dateFormat(item.created_at)))])]):_vm._e(),_vm._v(" "),(item)?_c('div',{staticClass:"small"},[(item.transaction_id)?_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"success"}},[_vm._v("\n              "+_vm._s(_vm.$t('pTalk_f_unlocked'))+"\n            ")]):_vm._e(),_vm._v(" "),(item.has_feedbacks === true)?_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"success"}},[_vm._v("\n              "+_vm._s(_vm.$t('pTalk_feedback_enabled'))+"\n            ")]):_vm._e(),_vm._v(" "),_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"info"}},[_c('font-awesome-icon',{attrs:{"icon":"comments"}}),_vm._v("\n              "+_vm._s(item.messages_count)+"\n            ")],1),_vm._v(" "),_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"warning"}},[_c('font-awesome-icon',{attrs:{"icon":"coins"}}),_vm._v("\n              "+_vm._s(item.messages_count)+"\n            ")],1)],1):_vm._e()]}},{key:"card(actions)",fn:function(ref){
var item = ref.data.item;
return [(item)?_c('b-dropdown-item-button',{attrs:{"variant":"info","size":"sm"},on:{"click":function($event){return _vm.onRead(item.id)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"book-open","size":"sm"}}),_vm._v("\n            "+_vm._s(_vm.$t('pTalk_readMessages'))+"\n          ")],1):_vm._e()]}},{key:"cell(user_a)",fn:function(ref){
var item = ref.data.item;
return [(item && item.user_a)?_c('user-profile-link',{attrs:{"user":item.user_a,"name-variant":(item.status_a === 'blocked') ? 'danger' : undefined}}):_vm._e()]}},{key:"cell(user_b)",fn:function(ref){
var item = ref.data.item;
return [(item && item.user_b)?_c('user-profile-link',{attrs:{"user":item.user_b,"name-variant":(item.status_b === 'blocked') ? 'danger' : undefined}}):_vm._e()]}},{key:"cell(status_a)",fn:function(ref){
var item = ref.data.item;
return [(item && item.status_a === 'blocked')?_c('b-badge',{directives:[{name:"shtml",rawName:"v-shtml",value:(item.status_a),expression:"item.status_a"}],attrs:{"variant":"danger"}}):(item && item.status_a)?_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(item.status_a),expression:"item.status_a"}]}):_vm._e()]}},{key:"cell(status_b)",fn:function(ref){
var item = ref.data.item;
return [(item && item.status_b === 'blocked')?_c('b-badge',{directives:[{name:"shtml",rawName:"v-shtml",value:(item.status_b),expression:"item.status_b"}],attrs:{"variant":"danger"}}):(item && item.status_b)?_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(item.status_b),expression:"item.status_b"}]}):_vm._e()]}},{key:"cell(transaction_id)",fn:function(ref){
var item = ref.data.item;
return [_c('span',{class:item && item.transaction_id ? 'text-success' : 'text-danger'},[(item && item.transaction_id)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'check']}}):_c('font-awesome-icon',{attrs:{"icon":['fas', 'xmark']}})],1)]}},{key:"cell(transaction_amount)",fn:function(ref){
var data = ref.data;
return [_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(data.field.formatter(data.item.transaction_amount, data.field.key, data.item)),expression:"data.field.formatter(data.item.transaction_amount, data.field.key, data.item)"}]})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.data.item;
return [_c('talk-btn',{attrs:{"talk":item}},[_vm._v("\n            "+_vm._s(_vm.$t('fCustomer_talks_btnShow'))+"\n          ")]),_vm._v(" "),(item.refunded_transaction_id === null && item.transaction_amount > 0)?_c('b-btn',{attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.onRefundTalk(item.id)}}},[_vm._v("\n            "+_vm._s(_vm.$t('fCustomer_talks_refund'))+"\n          ")]):_vm._e(),_vm._v(" "),(item.status_a !== 'blocked' && item.status_b !== 'blocked')?_c('b-btn',{attrs:{"talk":item,"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onBlockTalk(item.id)}}},[_vm._v("\n            "+_vm._s(_vm.$t('fCustomer_talks_block'))+"\n          ")]):_c('b-btn',{attrs:{"talk":item,"variant":"success","size":"sm"},on:{"click":function($event){return _vm.onUnlockTalk(item.id)}}},[_vm._v("\n            "+_vm._s(_vm.$t('fCustomer_talks_unlock'))+"\n          ")])]}},{key:"cell(has_feedbacks)",fn:function(ref){
var item = ref.data.item;
return [(item.has_feedbacks === true)?_c('button',{attrs:{"disabled":true,"variant":"success","size":"sm"}},[_vm._v("\n            "+_vm._s(_vm.$t('fCustomer_talks_feedback_enabled'))+"\n          ")]):_c('b-btn',{attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.onEnableFeedbacks(item.id)}}},[_vm._v("\n            "+_vm._s(_vm.$t('fCustomer_talks_feedback_disabled'))+"\n          ")])]}},{key:"cell(created_at)",fn:function(ref){
var ref_data = ref.data;
var item = ref_data.item;
var index = ref_data.index;
var field = ref_data.field;
return [(item)?_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(field.formatter(item.created_at, index, item)),expression:"field.formatter(item.created_at, index, item)"}]}):_vm._e()]}},{key:"cell(sent)",fn:function(ref){
var ref_data = ref.data;
var item = ref_data.item;
var index = ref_data.index;
var field = ref_data.field;
return [(item)?_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(field.formatter(item.last_message_sent, index, item)),expression:"field.formatter(item.last_message_sent, index, item)"}]}):_vm._e()]}},{key:"cell(market)",fn:function(ref){
var item = ref.data.item;
return [_c('market-flag',{attrs:{"market":item.user_a.market}}),_vm._v(" "),(item.user_a.market !== item.user_b.market)?_c('market-flag',{staticClass:"ml-1",attrs:{"market":item.user_b.market}}):_vm._e()]}}],null,true)}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }